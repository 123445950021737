export default {
    namespaced: true,

    state: () => {
        return {
            loadingModel: false,
            models: [],
        }
    },

    getters: {
        loadingModel(state) {
            return state.loadingModel;
        },

        models(state) {
            return state.models.map((item, sl) => {
                item.sl = sl + 1;
                item.display_text = `${item.name}`;
                return item;
            });
        },
    },

    mutations: {
        loadingModel(state, isLoading) {
            state.loadingModel = isLoading;
        },

        setModels(state, models) {
            state.models = models;
        },
    },

    actions: {
        async getModels(context, payload) {
            context.commit('loadingModel', true);

            let models = await axios.post(`${this.state.host}/get-product-models`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.product_models;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            context.commit('setModels', models);

            context.commit('loadingModel', false);
        },

        async saveModel(context, model) {
            let isSuccess = false;

            let url = 'add-product-model';

            if(model.id != null) {
                url = 'update-product-model';
            }

            await axios.post(`${this.state.host}/${url}`, model, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                context.dispatch('getModels');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return isSuccess;
        },

        async deleteModel(context, id) {
            await axios.delete(`${this.state.host}/delete-product-model/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.dispatch('getModel');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
